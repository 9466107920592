import { createContext } from 'react';
import { io, Socket } from 'socket.io-client';

type SocketInput = { client_id: string; user_pool_id: string; user_id?: string };

export const initSocket = (data: SocketInput) => {
  const socket = io(BACKEND_URL, { transports: ['websocket'] });
  socket.connect();

  socket.on('connect', () => {
    socket.emit('init', data);
  });
  return socket;
};

export const SocketContext = createContext<Socket | null>(null);
