// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';
import { HelmetProvider } from '/home/runner/work/vertriebsbutler-frontend/vertriebsbutler-frontend/node_modules/.pnpm/@umijs+renderer-react@4.3.34_react-dom@18.2.0_react@18.2.0/node_modules/@umijs/renderer-react';
import { context } from './helmetContext';

export const innerProvider = (container) => {
  return React.createElement(HelmetProvider, { context }, container);
}
