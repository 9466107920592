import './instrument';
import { RunTimeLayoutConfig, RuntimeAntdConfig } from '@umijs/max';
import './app.css';
import NoFoundPage from './pages/404';
import { InitialState, UserState } from './types/initialState';
import { CognitoService, getCognitoDataFromStorage } from './utils/auth';
import { theme } from 'antd/lib';
import { generate } from '@ant-design/colors';
import { AvatarDropdown } from './components/menu/AvatarDropdown';
import { initSocket, SocketContext } from './socket';
import AccountDisabled from './pages/402';
import NoAccess from './pages/403';
import { configApi } from './services/vertriebsbutler/config';

const clientConfig = await configApi.getConfig();
let collapseInit = false;
export const getInitialState = async (): Promise<InitialState | undefined> => {
  const storage = await getCognitoDataFromStorage();
  const cognito = new CognitoService(
    clientConfig.user_pool_id,
    clientConfig.client_id,
    storage?.auth_user_pool_id,
    storage?.auth_client_id,
  );
  await cognito.getSession();
  const userData = await cognito.getUserData();

  collapseInit = localStorage.getItem('collapsed') === 'true';
  const colorPrimary =
    clientConfig.styles.antd?.token?.colorPrimary || theme.defaultSeed.colorPrimary;
  const style = document.documentElement.style;
  const colors = generate(colorPrimary);
  style.setProperty('--bgGradient1', colors[5]);
  style.setProperty('--bgGradient2', colors[7]);
  style.setProperty('--bgCircle', colors[5]);
  style.setProperty('--bgColor', colors[5]);
  const userTheme = localStorage.getItem('theme');
  if (clientConfig) {
    return {
      user: {
        cognito,
        ...userData,
      },
      style: {},
      client: {
        ...clientConfig,
      },
      logos: {
        collapsed: '/logo_icon.svg',
        unCollapsed: clientConfig.logo || '/logo.svg',
      },
      collapsed: collapseInit,
      theme: userTheme === 'realDark' ? 'realDark' : 'light',
      disabled: clientConfig.disabled || false,
    };
  } else {
    console.error('No User Data');
  }
};

export const layout: RunTimeLayoutConfig = ({ initialState, setInitialState }) => {
  const { user } = initialState || {};
  const accessLevel = user?.role || UserState.unknown;

  return {
    logo: initialState?.logos.unCollapsed,
    title: '',
    pageTitleRender: () => {
      return 'Vertriebsbutler® | DIGITAL-ATEAM®';
    },
    collapsed: initialState?.collapsed,
    contentStyle: {
      minHeight: '100vh',
    },
    childrenRender: (children) => {
      const socket = initSocket({
        client_id: initialState?.client.client_id || '',
        user_pool_id: initialState?.client.user_pool_id || '',
        user_id: user?.id,
      });
      return <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>;
    },
    menu: {
      locale: false,
    },
    avatarProps: {
      render: (_, avatarChildren) => {
        return <AvatarDropdown>{avatarChildren}</AvatarDropdown>;
      },
    },
    onCollapse: (collapsed: boolean) => {
      if (collapseInit) {
        collapseInit = false;
        return;
      }
      localStorage.setItem('collapsed', collapsed ? 'true' : 'false');
      if (initialState) {
        setInitialState({
          ...initialState,
          collapsed,
        });
      }
    },

    // menuFooterRender: (props) => MenuFooter(props),
    unAccessible: initialState?.disabled ? (
      <AccountDisabled />
    ) : (
      <NoAccess directTo={window.location.pathname} />
    ),
    noFound: <NoFoundPage />,
    headerRender: initialState?.disabled ? false : undefined,
    menuRender:
      accessLevel !== UserState.unknown &&
      accessLevel !== UserState.guest &&
      !initialState?.disabled
        ? undefined
        : false,
    navTheme: initialState?.theme,
    layout: 'mix',
    contentWidth: 'Fluid',
    fixedHeader: false,
    fixSiderbar: true,
    splitMenus: false,
    pwa: true,
  };
};

export const antd: RuntimeAntdConfig = (config) => {
  const colorPrimary =
    clientConfig.styles.antd?.token?.colorPrimary || theme.defaultSeed.colorPrimary;
  config.theme = {
    token: {
      fontSize: 18,
      ...clientConfig.styles.antd,
      colorPrimary,
      colorLink: colorPrimary,
    },
    components: clientConfig.styles.antd.components,
  };
  return config;
};
