export default {
  'project.delete.title': 'Projekt löschen',
  'project.delete.message':
    'Soll das Projekt wirklich gelöscht werden? Es werden damit auch alle Kampagnen und Dokumente gelöscht.',
  'project.delete.success': 'Das Projekt wurde gelöscht',
  'project.delete.error': 'Das Projekt konnte nicht gelöscht werden',
  'project.edit.success': 'Das Projekt wurde gespeichert',
  'project.add.success': 'Das Projekt wurde hinzugefügt',
  'project.profile.address': 'Adresse',
  'project.profile.name': 'Name',
  'project.profile.note': 'Notiz',
  'project.profile.title': 'Projekt',
  'project.edit.title': 'Projekt bearbeiten',
  'project.add.title': 'Projekt hinzufügen',
  'project.label.name': 'Name',
  'project.memomeister.title': 'Memomeister',
  'project.memomeister.project_folder': 'Projektordner',
  'project.memomeister.project_template': 'Projektvorlage',
  'project.memomeister.project_access': 'Zugriff',
  'project.memomeister.label': 'Label',
  'project.memomeister.label2': 'setzen',
  'project.memomeister.new_label': 'Neues Label',
  'project.memomeister.access': 'Zugriff',
  'project.craftnote.title': 'Craftnote',
  'project.craftnote.project_folder': 'Projektordner',
  'project.craftnote.access': 'Zugriff',
  'project.delete.error2':
    'Das Projekt kann nicht gelöscht werden. Bitte beenden Sie zuerst alle laufenden Kampagnen.',
  'project.link.success': 'Die Anfrage wurde mit dem Projekt verknüpft',
  'inquiry.name': 'Anfrage',
  'project.validation.name': 'Bitte geben Sie einen Namen ein',
  'project.profile.general': 'Allgemein',
};
