import { useEffect, useRef, useState } from 'react';
import { Spin } from 'antd';
import { FileViewer } from '@digital-a-team/platform-file-viewer';
import { useModel, useParams } from '@umijs/max';
import { VBCampaign, VBCampaignAction } from '@/services/vertriebsbutler/campaign';
import { File as DBFile, ObjectCannedAcl, Tag_File } from '@/generated/graphql';
import FileHandler from '@/utils/s3';
import 'video-react/dist/video-react.css';
import './CountDown.css';

const CampaignRecordVideo = () => {
  const [files, setFiles] = useState<Array<File>>();
  const params = useParams<{ campaign_id: string }>();
  const [contact_id, setContact_id] = useState<string>();
  const [project_id, setProjectId] = useState<string>();
  const parts = useRef<Array<{ ETag: string; PartNumber: number }>>([]);
  const keyRef = useRef<string>('');
  const uploadIdRef = useRef<string>('');
  const [fh, setFh] = useState<FileHandler>();
  const [campaign_number, setCampaign_number] = useState<string>();
  const { initialState } = useModel('@@initialState');
  const [folderName, setFolderName] = useState<string>();

  const getData = async () => {
    if (!params.campaign_id) {
      console.error('No campaign id provided');
      return;
    }

    setFh(await FileHandler.init());
    const { data } = await VBCampaign.selectFiles(params.campaign_id);
    if (!data) {
      console.error('No data found');
      return;
    }
    if (data.files.length === 0) {
      console.error('No files found');
      return;
    }
    setFolderName(data.files[0].folder || '');
    setContact_id(data.contact_id);
    setCampaign_number(data.number || '');

    if (data && data.files && data.files.length > 0) {
      setProjectId(data.project_id);
      const fh = await FileHandler.init();
      const filesArray = await Promise.all(
        data.files
          .filter((file: DBFile) => file.s3key && file.mime?.indexOf('video') === -1)
          .sort((a: DBFile) => (a.tags && a.tags.includes(Tag_File.OfferFile) ? 1 : -1))
          .map(async (file: DBFile) => {
            const f = await fh.download(
              file.name || 'Datei',
              file.s3key as string,
              file.mime || '',
            );
            return f ? f.data : null;
          }),
      );
      const validFiles = filesArray.filter((file) => file) as unknown as Array<File>;
      setFiles(validFiles);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (!files || !files.length) return <Spin />;

  const onClose = () => {
    history.back();
  };

  return (
    <FileViewer
      files={files}
      defaultFileName={'Angebotsvideo'}
      showCampaignStart={true}
      onStartMultipartUpload={async () => {
        const folder = `contact/${contact_id}/project/${project_id}/campaign/${params.campaign_id}`;
        const filename = `Angebotsvideo.webm`;
        const s3Key = `${folder}/${filename}`;
        const awsTags = [
          {
            name: 'contact_id',
            value: contact_id?.toString() || '',
          },
          {
            name: 'project_id',
            value: project_id?.toString() || '',
          },
          { name: 'client_id', value: initialState?.client?.client_id || '' },
          { name: 'user_pool_id', value: initialState?.client?.user_pool_id || '' },
          { name: 'campaign_id', value: params.campaign_id || '' },
        ];
        const res = await fh?.initMultipart({
          s3Key,
          contentType: 'video/webm',
          campaign_id: params.campaign_id,
          acl: ObjectCannedAcl.PublicRead,
          preInsert: false,
          awsTags,
        });
        if (res?.data?.res.multipartUploadId) {
          keyRef.current = s3Key;
          uploadIdRef.current = res?.data?.res.multipartUploadId;
        }
      }}
      onUploadPart={async (part: number, body: Blob) => {
        const res = await fh?.uploadMultipart(keyRef.current, part, uploadIdRef.current, body);
        if (!res) throw new Error('Upload failed');
        parts.current.push(res);
      }}
      onCompleteMultipartUpload={async (
        filename: string,
        campaignStart: boolean,
        size: number,
        duration: number,
        thumbnail: Blob,
      ) => {
        if (!contact_id || !project_id) return false;
        const awsTags = [
          {
            name: 'contact_id',
            value: contact_id?.toString() || '',
          },
          {
            name: 'project_id',
            value: project_id?.toString() || '',
          },
          { name: 'client_id', value: initialState?.client?.client_id || '' },
          { name: 'user_pool_id', value: initialState?.client?.user_pool_id || '' },
          { name: 'campaign_id', value: params.campaign_id || '' },
        ];
        const thumbName = filename.replace('.webm', '_thumb.jpg');
        const thumbKey = `${folderName}/${thumbName}`;
        fh?.upload({
          acl: ObjectCannedAcl.PublicRead,
          awsTags,
          Body: new File([thumbnail], `${thumbName}`),
          contentType: 'image/jpeg',
          preInsert: false,
          s3Key: thumbKey,
        });

        await fh?.completeMultipartUpload(
          {
            s3key: keyRef.current,
            backgroundProcess: {
              fixVideo: true,
              thumbnail: true,
            },
            multipart: {
              uploadId: uploadIdRef.current,
              parts: parts.current.sort((a, b) => a.PartNumber - b.PartNumber),
            },
            filename,
            contentType: 'video/webm',
          },
          {
            folder: folderName,
            s3key: keyRef.current,
            name: filename,
            thumbnail_s3key: thumbKey,
            mime: 'video/webm',
            size,
            tags: {
              set: [Tag_File.OfferVideo],
            },
            url: fh?.getUrl(keyRef.current),
            client: {
              connect: {
                client_id: initialState?.client?.client_id,
              },
            },
            ...(params.campaign_id && {
              campaign: {
                connect: {
                  id: params.campaign_id,
                },
              },
            }),
            ...(contact_id && {
              contact: {
                connect: {
                  id: contact_id,
                },
              },
            }),
            ...(project_id && {
              project: {
                connect: {
                  id: project_id,
                },
              },
            }),
          },
        );

        const data = {
          campaign_id: params.campaign_id || '',
          main_state: 'video',
          sub_state: 'recorded',
          message: '',
          ext_file_id: '',
          ext_thumbnail_id: '',
          start_now: campaignStart,
          file_name: `${filename}.webm`,
          s3Key: keyRef.current,
          url: '',
        };

        const res = await VBCampaignAction.insertVideoRecorded(data);
        if (res.data) {
          return Promise.resolve(true);
        }
        return Promise.resolve(false);
      }}
      onAbortMultipartUpload={async () => {
        parts.current = [];
        await fh?.abortMultipartUpload(keyRef.current, uploadIdRef.current);
      }}
      onClose={onClose}
    />
  );
};

export default CampaignRecordVideo;
