export default {
  'agentList.sort.created': 'Erstellt',
  'agentList.sort.firstname': 'Vorname',
  'agentList.sort.lastname': 'Nachname',
  'agentList.add': 'Mitglied hinzufügen',
  'agent.delete.title': 'Mitglied löschen',
  'agent.delete.message': 'Möchten Sie das Mitglied wirklich löschen?',
  'agent.delete.success': 'Mitglied erfolgreich gelöscht',
  'agent.add.title': 'Mitglied hinzufügen',
  'agent.edit.title': 'Mitglied bearbeiten',
  'agent.add.success': 'Mitglied erfolgreich hinzugefügt',
  'agent.edit.success': 'Mitglied erfolgreich bearbeitet',
  'agent.list.title': 'Team Mitglieder',
  'agentView.title': 'Team Mitglieder',
};
