export default {
  'customer.list.title': 'Benutzer',
  'customerList.add': 'Benutzer hinzufügen',
  'customer.delete.title': 'Benutzer löschen',
  'customer.delete.message': 'Möchten Sie das Benutzer wirklich löschen?',
  'customer.delete.success': 'Benutzer erfolgreich gelöscht',
  'customer.add.title': 'Benutzer hinzufügen',
  'customer.edit.title': 'Benutzer bearbeiten',
  'customer.add.success': 'Benutzer erfolgreich hinzugefügt',
  'customer.edit.success': 'Benutzer erfolgreich bearbeitet',
  'customerView.title': 'Benutzer',
  'customerList.sort.created': 'Erstellt',
  'customerList.sort.firstname': 'Vorname',
  'customerList.sort.lastname': 'Nachname',
};
