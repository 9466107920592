import { history } from '@umijs/max';
import { Button, Result } from 'antd';

const NoFoundPage = () => {
  return (
    <Result
      status="404"
      title="Seite nicht gefunden"
      subTitle="Die angeforderte Seite wurde nicht gefunden."
      extra={
        <Button size={'large'} type="primary" onClick={() => history.push('/')}>
          Zurück zur Startseite
        </Button>
      }
    />
  );
};

export default NoFoundPage;
