export default async function createServiceWorker(): Promise<ServiceWorkerRegistration | null> {
  if ('serviceWorker' in navigator) {
    try {
      const url = '/serviceWorker.js';
      const reg = await navigator.serviceWorker.register(url, { scope: '/' });
      if (reg) {
        console.log('Service Worker registered with scope:', reg.scope);
        return reg;
      } else {
        console.error('Service Worker registration failed. Registration object is null.');
        return null;
      }
    } catch (error) {
      console.error('Service Worker registration failed:', error);
      return null;
    }
  } else {
    console.error('Service Worker is not supported in this browser.');
    return null;
  }
}
