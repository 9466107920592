export default {
  'component.fileExplorer.title': 'Dokumente',
  'component.fileExplorer.folder': 'Ordner',
  'component.fileExplorer.upload.title': 'Dokument(e) hochladen',
  'component.fileExplorer.subfolder': 'Neuer Unterordner',
  'component.file.required': 'Bitte wählen Sie ein Dokument aus',
  'component.fileExplorer.upload.success': 'Dokument(e) erfolgreich hochgeladen',
  'component.file.title': 'Dokument(e)',
  'dragger.file.title': 'Dokument(e) hier ablegen oder klicken',
  'dragger.file.description.max': 'Maximale Dateien: ',
  'component.fileExplorer.folder.required': 'Bitte geben Sie einen Ordner an',
};
