import { history, useModel } from '@umijs/max';
import { Button, Result } from 'antd';
import { Base64 } from 'js-base64';
import { UserState } from '../types/initialState';

const NoAccess = (props: { directTo: string }) => {
  const { initialState } = useModel('@@initialState');
  if (props.directTo && initialState?.user.role === UserState.unknown)
    history.push('/user/login/' + Base64.encode(props.directTo));
  return (
    <Result
      status="403"
      title="Kein Zugriff"
      subTitle="Kein Zugriff auf diese Seite."
      extra={
        <Button size={'large'} type="primary" onClick={() => history.push('/user/login')}>
          Zum Anmeldung
        </Button>
      }
    />
  );
};
export default NoAccess;
