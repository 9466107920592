export default {
  'parameter.label.json': 'JSON',
  'parameter.label.lastname': 'Nachname',
  'parameter.label.firstname': 'Vorname',
  'parameter.label.key': 'API-Schlüssel',
  'parameter.label.privateKey': 'Privater Schlüssel',
  'parameter.label.publicKey': 'Öffentlicher Schlüssel',
  'parameter.label.email': 'E-Mail',
  'parameter.label.password': 'Passwort',
  'parameter.label.json.required': 'Bitte geben Sie einen gültigen JSON-String ein',
};
