import * as Sentry from '@sentry/react';
if (REACT_APP_ENV !== 'local-dev') {
  Sentry.init({
    dsn: 'https://589db2650978ad8483974331ab886d32@o4508034820145152.ingest.de.sentry.io/4508042360324176',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ['localhost', /^https:\/\/(.*\.)?vertriebsbutler\.com\/.*/],
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: REACT_APP_ENV,
  });
}
