export default {
  'contactList.title': 'Kontaktliste',
  'contactList.filter.quickFilter.activeUser': 'Meine Kontakte',
  'contactList.sort.created': 'Erstellt',
  'contactList.sort.lastAction': 'Letzte Aktion',
  'contactList.sort.firstname': 'Vorname',
  'contactList.sort.lastname': 'Nachname',
  'contactList.sort.companyName': 'Firma',
  'contactList.sort.agent': 'Benutzer',
  'contactList.tooltip.created': 'Erstellt',
  'contactList.tooltip.lastAction': 'Letzte Aktion',
  'contactList.tooltip.campaign': 'Kampagne',
  'contactList.tooltip.last_state': 'Letzter Status',
  'contactList.tooltip.project': 'Projekt',
  'contactView.title': 'Kontakt',
  'contactView.profile.title': 'Profil',
  'contactView.profile.name': 'Name',
  'contactView.profile.company': 'Firma',
  'contactView.profile.address': 'Adresse',
  'contactView.profile.phone': 'Telefon',
  'contactView.profile.email': 'E-Mail',
  'contactView.profile.mobile': 'Mobil',
  'contactView.contact.edit': 'Kontakt bearbeiten',
  'contactView.profile.note': 'Notiz',
  'contactView.projects.title': 'Projekte',
  'contactView.project.title': 'Projekt',
  'contactView.projects.campaigns.title': 'Kampagnen',
  'contactView.projects.campaigns.add': 'Kampagne hinzufügen',
  'contactView.projects.campaigns.campaign.created': 'Erstellt',
  'contactView.projects.campaigns.campaign.lastAction': 'Letzte Aktion',
  'contactView.projects.campaigns.campaign.lastState': 'Letzter Status',
  'contactView.projects.documents.title': 'Dokumente',
  'contactView.projects.campaign.title': 'Kampagne',
  'contactView.inquiry.title': 'Offene Anfragen',
  'inquiry.project': 'Einem Projekt zuweisen',
  'inquiry.project.remove': 'Die Anfrage wurde entfernt',
  'inquiry.delete.title': 'Anfrage löschen',
  'inquiry.delete.message': 'Soll die Anfrage wirklich gelöscht werden?',
  'contact.delete.title': 'Kontakt löschen',
  'contact.delete.message':
    'Sind Sie sicher, dass dieser Kontakt endgültig gelöscht werden soll? Es werden damit auch alle Projekte, Kampagnen und Dokumente gelöscht.',
  'contact.delete.success': 'Kontakt wurde gelöscht',
  'contact.edit.title': 'Kontakt bearbeiten',
  'contact.add.title': 'Kontakt hinzufügen',
  'contact.add.success': 'Kontakt wurde hinzugefügt',
  'contact.edit.success': 'Kontakt wurde bearbeitet',
  'contact.delete.error':
    'Der Kontakt kann nicht gelöscht werden. Bitte beenden Sie zuerst alle laufenden Kampagnen.',
  'contact.archive.error':
    'Der Kontakt kann nicht archiviert werden. Bitte beenden Sie zuerst alle laufenden Kampagnen.',
  'contact.archive.title': 'Kontaktarchiv',
  'contact.archive.action.restore': 'Wiederherstellen',
  'contact.archive.success': 'Kontakt wurde archiviert',
  'contact.archive.error.restore': 'Der Kontakt kann nicht wiederhergestellt werden',
  'contact.archive.action.archive': 'Archivieren',
  'contact.archive.restore.success': 'Kontakt wurde wiederhergestellt',
};
