export default {
  'avatar.list.title': 'Avatare',
  'avatar.delete.success': 'Avatar erfolgreich gelöscht',
  'avatar.edit.title': 'Avatar bearbeiten',
  'avatar.add.title': 'Avatar hinzufügen',
  'avatar.label.name': 'Name',
  'avatar.name.required': 'Name ist erforderlich',
  'avatar.ext_avatar_id.required': 'Externe Avatar-ID ist erforderlich',
  'avatar.label.ext_avatar_id': 'Externe Avatar-ID',
  'avatar.label.intro': 'Intro',
  'avatar.label.characteristic': 'Charakteristik',
  'avatar.add.success': 'Avatar erfolgreich hinzugefügt',
  'avatar.edit.success': 'Avatar erfolgreich bearbeitet',
  'avatar.delete.title': 'Avatar löschen',
  'avatar.delete.message': 'Möchten Sie diesen Avatar wirklich löschen?',
  'avatar.label.knowledgebase': 'Wissensdatenbank',
  'avatar.label.preview': 'Vorschau',
  'avatar.label.config': 'Konfiguration',
  'avatar.label.settings': 'Einstellungen',
  'avatar.document.empty': ' Keine Dokumente vorhanden',
  'avatar.document.dragndrop':
    'Ziehen Sie Dokumente hierher oder klicken Sie, um Dateien hochzuladen',
};
