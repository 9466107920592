import { Result } from 'antd'

const AccountDisabled = () =>
{
  return (
    <Result
      status="403"
      title="Zugang gesperrt"
      subTitle="Der Zugang wurde gesperrt"
    />
  )
}
export default AccountDisabled
