export default {
  'clientList.title': 'Kundenliste',
  'clientList.tooltip.client_id': 'Client-ID',
  'clientList.tooltip.userpool_id': 'Userpool-ID',
  'clientList.tooltip.butler': 'Instanz',
  'client.edit.title': 'Kunde bearbeiten',
  'client.add.title': 'Kunde hinzufügen',
  'client.add.success': 'Kunde wurde erfolgreich hinzugefügt',
  'client.edit.success': 'Kunde wurde erfolgreich bearbeitet',
  'client.delete.message': 'Möchten Sie diesen Kunden wirklich löschen?',
  'client.delete.title': 'Kunde löschen',
  'client.profile.title': 'Kundenprofil',
  'client.profile.name': 'Name',
  'client.profile.domain': 'Instanz',
  'client.profile.dkim': 'DKIM',
  'client.user.title': 'Benutzer',
  'client.parameter.title': 'Parameter',
  'client.survey.title': 'Fragebögen',
  'client.list.title': 'Listen',
  'client.profile.userpool_id': 'Userpool-ID',
  'client.profile.client_id': 'Client-ID',
  'client.profile.extern_domain': 'Domains',
  'client.label.name': 'Firmenname',
  'client.label.instance': 'Instanzname',
  'client.label.domain': 'Instanz',
  'client.label.embed_domain': 'Webseiten',
  'client.label.embed_domain.add': 'Webseite hinzufügen',
  'client.label.logo': 'Logo',
  'client.label.styles': 'Styles',
  'client.label.dkim': 'DKIM-Adresse',
  'client.rule.required.instance': 'Bitte geben Sie eine Instanz an',
  'client.rule.pattern.instance': 'Nur Kleinbuchstaben, Bindestrich und Zahlen sind erlaubt',
  'client.rule.max.instance': 'Maximal 25 Zeichen erlaubt',
  'client.rule.required.styles': 'Kein gültiges JSON Format',
  'client.rule.required.name': 'Firmenname ist ein Pflichtfeld',
  'client.rule.required.dkim': 'DKIM ist ein Pflichtfeld',
  'client.rule.required.embed_domain': 'Webseiten ist ein Pflichtfeld',
  'client.rule.required.logo': 'Logo ist ein Pflichtfeld',
  'client.disable.success': 'Instanz wurde erfolgreich deaktiviert',
  'client.enable.success': 'Instanz wurde erfolgreich aktiviert',
  'client.action.disable': 'Instanz sperren',
  'client.action.enable': 'Instanz aktivieren',
  'client.disabled': 'Instanz gesperrt',
};
