export default {
  'global.title': 'Vertriebsbutler',
  'global.menu.dashboard': 'Dashboard',
  'global.menu.contact': 'Kontakte',
  'global.menu.admin': 'Adminpanel',
  'global.menu.login': 'Login',
  'global.menu.logout': 'Logout',
  'global.menu.register': 'Registrieren',
  'global.menu.lostPassword': 'Passwort vergessen',
  'global.action.edit': 'Bearbeiten',
  'global.action.delete': 'Löschen',
  'global.action.save': 'Speichern',
  'global.action.cancel': 'Abbrechen',
  'global.action.back': 'Zurück',
  'global.action.add': 'Hinzufügen',
  'global.action.search': 'Suchen',
  'global.action.reset': 'Zurücksetzen',
  'global.action.clear': 'Leeren',
  'global.action.filter': 'Filtern',
  'global.action.sort': 'Sortieren',
  'global.action.view': 'Anzeigen',
  'global.action.start': 'Starten',
  'global.action.upload': 'Hochladen',
  'global.action.disable': 'sperren',
  'global.created': 'Erstellt:',
  'global.modal.delete.confirm': 'Löschen',
  'global.modal.cancel': 'Abbrechen',
  'global.error': 'Ein Fehler ist aufgetreten',
  'global.modal.edit.confirm': 'Speichern',
  'global.error.client_id': 'Mandant konnte nicht identifiziert werden',
  'global.error.contact_id': 'Kontakt konnte nicht identifiziert werden',
  'global.label.salutation': 'Anrede',
  'global.label.firstname': 'Vorname',
  'global.label.lastname': 'Nachname',
  'global.label.company': 'Firma',
  'global.label.phone': 'Telefon',
  'global.label.email': 'E-Mail',
  'global.label.mobile': 'Mobil',
  'global.label.note': 'Notiz',
  'global.label.salutation.mr': 'Herr',
  'global.label.salutation.mrs': 'Frau',
  'global.label.salutation.mx': 'Divers',
  'global.label.salutation.fm': 'Familie',
  'global.label.salutation.cp': 'Firma',
  'global.label.formal': 'Ansprache',
  'global.label.formal.formal': 'förmlich',
  'global.label.formal.informal': 'persönlich',
  'global.label.email.required': 'E-Mail ungültig',
  'global.label.agent': 'Benutzer',
  'global.label.country': 'Land',
  'global.label.street': 'Strasse und Hausnummer',
  'global.label.zip': 'PLZ',
  'global.label.city': 'Stadt',
  'global.label.title': 'Titel',
  'global.label.text': 'Text',
  'global.clipboard.copy': 'Element wurde in die Zwischenablage kopiert',
  'global.label.email.unique':
    'Diese E-Mail wird bereits verwendet. (Bitte auch das Archiv prüfen) ',
  'global.tooltip.created': 'Erstellt',
  'global.tooltip.updated': 'Aktualisiert',
  'global.tooltip.state': 'Letzter Status',
  'global.tooltip.email': 'E-Mail',
  'global.tooltip.project': 'Projekt',
  'global.tooltip.campaign': 'Kampagne',
  'global.tooltip.phone': 'Telefon',
  'global.tooltip.mobile': 'Mobil',
  'global.tooltip.agent': 'Benutzer',
  'global.table.new.row': 'Datensatz hinzufügen',
  'global.table.new.column': 'Spalte hinzufügen',
  'global.table.delete.row': 'Datensatz löschen',
  'global.label.lastname.required': 'Nachname ist ein Pflichtfeld',
  'global.label.firstname.required': 'Vorname ist ein Pflichtfeld',
  'global.label.company.required': 'Firma ist ein Pflichtfeld',
  'global.modal.end.confirm': 'Kampagne beenden',
};
