export default {
  'campaign.delete.title': 'Kampagne löschen',
  'campaign.delete.message': 'Möchtest du die Kampagne wirklich löschen?',
  'campaign.delete.success': 'Kampagne erfolgreich gelöscht',
  'campaign.delete.error': 'Kampagne konnte nicht gelöscht werden',
  'campaign.lastState': 'Letzter Status',
  'campaign.title': 'Kampagne',
  'campaign.add.success': 'Kampagne erfolgreich gestartet',
  'campaign.add.title': 'Kampagne starten',
  'campaign.add.type': 'Kampagnentyp',
  'campaign.type.appointment': 'Termineinladung',
  'campaign.type.offer': 'Angebot',
  'campaign.type.rating': 'Bewertung',
  'campaign.offer.number': 'Angebotsnummer',
  'campaign.offer.description': 'Zusätzlicher Text für E-Mails',
  'campaign.offer.delivery': 'Versandart',
  'campaign.offer.delivery.normal': 'Ohne Video',
  'campaign.offer.delivery.video': 'Mit Video',
  'campaign.offer.file.required': 'Datei ist erforderlich',
  'campaign.offer.file': 'Angebotsdatei',
  'campaign.offer.other.files': 'Weitere Dateien',
  'campaign.offer.number.required': 'Angebotsnummer ist erforderlich',
  'global.action.end': 'Kampagne beenden',
  'campaign.end.title': 'Kampagne beenden',
  'campaign.end.message': 'Kampagne wirklich beenden?',
  'campaign.end.success': 'Kampagne erfolgreich beendet',
  'campaign.end.failure': 'Kampagne konnte nicht beendet werden',
  'campaign.cronjobs.title': 'Jobs',
  'campaign.details.title': 'Details',
};
