export default {
  'user.edit.title': 'Benutzer bearbeiten',
  'user.add.title': 'Benutzer hinzufügen',
  'user.label.name': 'Name',
  'user.delete.title': 'Benutzer löschen',
  'user.delete.message': 'Möchten Sie diesen Benutzer wirklich löschen?',
  'user.title': 'Benutzer',
  'user.add.success': 'Benutzer erfolgreich hinzugefügt',
  'user.edit.success': 'Benutzer erfolgreich bearbeitet',
  'user.passwort.sent': 'E-Mail zur Zurücksetzung des Passwortes wurde gesendet',
  'user.send.password': 'Passwort zurücksetzen',
  'user.delete.success': 'Benutzer erfolgreich gelöscht',
  'user.delete.error': 'Benutzer konnte nicht gelöscht werden',
  'user.delete.agent': 'Kontakte übertragen',
  'user.required': 'Benutzer ist erforderlich',
};
