import React, { useState, useEffect, FC } from 'react';
import { UserState } from '@/types/initialState';
import { ControlOutlined, LockOutlined, LogoutOutlined } from '@ant-design/icons';
import { ProCard } from '@ant-design/pro-components';
import { history, useModel } from '@umijs/max';
import { Button, Col, Row, Tooltip, Typography } from 'antd';
import { ReactNode } from 'react';
import { motion } from 'framer-motion';
import { theme } from 'antd';

interface NameTickerProps {
  firstname?: string;
  lastname?: string;
}

const AnimatedText: React.FC<NameTickerProps> = ({ firstname, lastname }) => {
  const [displayedName, setDisplayedName] = useState('');
  const { token } = theme.useToken();

  useEffect(() => {
    const animateName = () => {
      const nameArray = (firstname + ' ' + lastname).split('');

      const intervalId = setInterval(() => {
        if (nameArray.length > 0) {
          setDisplayedName((prev) => prev + nameArray.shift());
        } else {
          clearInterval(intervalId);
          setTimeout(() => {
            setDisplayedName('');
            animateName();
          }, 5000);
        }
      }, 80);

      return () => clearInterval(intervalId);
    };

    animateName();
  }, [firstname, lastname]);

  return (
    <Typography.Paragraph style={{ position: 'relative', minHeight: '60px' }}>
      <span
        style={{
          display: 'block',
        }}
      >
        {`Angemeldet als `}
      </span>
      <motion.span
        style={{
          position: 'absolute',
          fontWeight: '700',
          fontSize: '22px',
          color: token.colorPrimary,
        }}
      >
        {displayedName}
      </motion.span>
    </Typography.Paragraph>
  );
};

const MenuActionWrapper = (props: { collapsed: boolean; children: ReactNode }) => {
  if (props.collapsed) return <>{props.children}</>;
  return <ProCard>{props.children}</ProCard>;
};

const PopOverWrapper = (props: { collapsed: boolean; children: ReactNode; text: string }) => {
  if (props.collapsed)
    return (
      <Tooltip placement={'right'} title={props.text}>
        {props.children}
      </Tooltip>
    );
  return <>{props.children}</>;
};

type UserMenuProps = {
  afterClick?: () => void;
  collapsed: boolean;
};

export const UserMenu: FC<UserMenuProps> = (props) => {
  const { initialState } = useModel('@@initialState');
  if (!initialState) return null;

  const { user } = initialState;
  const buttons = [
    {
      text: 'Administration',
      icon: <ControlOutlined />,
      access: initialState.user.role === UserState.su,
      url: '/admin',
    },
    {
      text: 'Verwaltung',
      icon: <ControlOutlined />,
      access: initialState.user.role === UserState.admin,
      url: '/customer',
    },

    /* { text: 'Profil', icon: <UserOutlined /> }, */
    {
      text: 'Passwort ändern',
      icon: <LockOutlined />,
      url: '/user/change-password',
    },
    {
      text: 'Logout',
      icon: <LogoutOutlined />,
      danger: true,
      action: () => {
        initialState.user.cognito?.signOut();
        initialState.user.role = UserState.unknown;
      },
      url: '/user/login',
    },
  ];

  return (
    <MenuActionWrapper collapsed={props.collapsed}>
      <Row gutter={[8, 8]}>
        {props.collapsed ? null : (
          <Col span={24}>
            <AnimatedText firstname={user.firstname} lastname={user.lastname} />
          </Col>
        )}
        {buttons.map(
          (button, index) =>
            (button.access === undefined || button.access) && (
              <Col span={24} key={index}>
                <PopOverWrapper text={button.text} collapsed={props.collapsed}>
                  <Button
                    block
                    danger={button.danger}
                    type="dashed"
                    icon={button.icon}
                    onClick={() => {
                      button.action?.();
                      if (button.url) {
                        history.push(button.url);
                      }
                      props.afterClick?.();
                    }}
                  >
                    {props.collapsed ? '' : button.text}
                  </Button>
                </PopOverWrapper>
              </Col>
            ),
        )}
      </Row>
    </MenuActionWrapper>
  );
};

export default AnimatedText;
