export default {
  'list.edit.title': 'Liste bearbeiten',
  'list.add.title': 'Liste hinzufügen',
  'list.label.name': 'Name',
  'list.delete.title': 'Liste löschen',
  'list.delete.message': 'Soll die Liste wirklich gelöscht werden?',
  'list.delete.success': 'Liste wurde gelöscht',
  'list.title': 'Listen',
  'list.save.success': 'Liste wurde gespeichert',
  'list.field.key': 'Schlüssel',
  'list.field.title': 'Titel',
  'list.field.type': 'Daten Typ',
  'list.field.type.text': 'Text',
  'list.field.type.number': 'Nummer',
  'list.field.readOnly': 'Schreibgeschützt',
  'list.field.visible': 'Sichtbar',
  'list.field.required': 'Pflichtfeld',
  'list.label.title': 'Name',
  'list.save.error.title': 'Die Liste muss einen Namen haben',
};
