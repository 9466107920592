import Mustache from 'mustache';
import { mutate, query } from '.';
import { OperationVariables, gql } from '@apollo/client';
import { SortOrder } from '@/generated/graphql';

interface KeyValueObject {
  [key: string]: string | number | boolean | KeyValueObject | Array<KeyValueObject>;
}

type NamespaceKey<TKey extends string, TValue> = { [K in TKey]: TValue };

type SortItem = {
  field: string;
  type: 'sortOrder' | 'sortOrderInput';
  direction?: SortOrder.Asc | SortOrder.Desc;
};

export class VBBaseClass<T> {
  private MUTATION_SKELETON = `mutation Mutation {{param1}}  {{openBrace}} {{namespaceLC}}: {{command}} {{{param2}}} {{openBrace}} {{select}} {{closeBrace}}{{closeBrace}}`;
  private QUERY_SKELETON = `query Query {{param1}} {{openBrace}} {{namespaceLC}}: {{command}} {{{param2}}}  {{openBrace}} {{select}} {{closeBrace}}{{closeBrace}}`;
  private openBrace = '{';
  private closeBrace = '}';
  private typedNamespace: string;
  private outputNamespace;

  constructor(
    private namespace: string,
    private default_fields: string,
    private objectList: Array<string> = [],
  ) {
    this.typedNamespace = namespace.toLowerCase();
    this.outputNamespace = namespace;
  }

  private iterateData(
    obj: KeyValueObject,
    type: 'create' | 'update' | 'delete',
    level = 0,
    cascadeTables: Array<string> = [],
    isConnect = false,
  ): OperationVariables {
    const data: OperationVariables = {};
    const date = new Date().toISOString();
    if (type !== 'delete') {
      for (const key in obj) {
        if (!['created', 'updated', '__typename'].includes(key)) {
          if (
            (obj.hasOwnProperty(key) && type === 'create' && key !== 'id' && level === 0) ||
            type === 'update' ||
            level > 0
          ) {
            const value = obj[key] === undefined ? null : obj[key];
            if (value) {
              if (
                !this.objectList.includes(key) &&
                (typeof value === 'object' || Array.isArray(value))
              ) {
                const obj = Array.isArray(value)
                  ? this.iterateData(value[0] as KeyValueObject, type, 1, [], true)
                  : this.iterateData(value as KeyValueObject, type, 1, [], true);
                if (obj.data.length > 0 || Object.keys(obj.data).length > 0) {
                  data[key] = {
                    connect: {
                      ...obj.data,
                    },
                  };
                }
              } else if (this.objectList.includes(key)) {
                data[key] = JSON.parse(JSON.stringify(value));
              } else {
                data[key] = type === 'create' || level > 0 ? value : { set: value };
              }
            } else {
              if (!isConnect) data[key] = type === 'create' || level > 0 ? null : { set: null };
            }
          }
        }
      }
    } else {
      data['deleted'] = { set: date };
      cascadeTables.forEach((table) => {
        data[table] = {
          updateMany: {
            data: {
              deleted: {
                set: date,
              },
            },
            where: {
              [`${this.namespace.toLowerCase()}_id`]: { equals: obj.id },
              AND: [
                {
                  deleted: {
                    equals: null,
                  },
                },
              ],
            },
          },
        };
      });
    }
    return { data };
  }

  // clientIdValid(data: T) {
  //   const obj = Object(data);
  //   let client_id = undefined;
  //   if (obj.hasOwnProperty('client')) {
  //     const client = obj.client;
  //     if (client.hasOwnProperty('client_id')) {
  //       const id = Object.entries(client).find(([key, value]) => {
  //         return key === 'client_id' && value;
  //       });
  //       if (id) client_id = id[1];
  //     }
  //   } else if (obj.hasOwnProperty('client_id')) {
  //     const id = Object.entries(obj).find(([key, value]) => {
  //       return key === 'client_id' && value;
  //     });
  //     if (id) client_id = id[1];
  //   }
  //   if (!client_id) throw new Error('Client id is missing');
  //   return client_id;
  // }

  setVariables(orderBy: Array<SortItem>, params?: { [key: string]: string | boolean | null }) {
    const variables: OperationVariables = {};
    if (orderBy)
      variables.orderBy = orderBy.map((item) => ({
        [item.field]:
          item.type === 'sortOrder'
            ? item.direction || SortOrder.Asc
            : {
                sort: item.direction || SortOrder.Asc,
              },
      }));

    const conditions = [];
    if (!params) return {};
    for (const key of Object.keys(params)) {
      switch (params[key]) {
        case '!null':
          conditions.push({ [key]: { not: null } });
          break;
        default:
          conditions.push({ [key]: { equals: params[key] } });
      }
    }

    if (conditions.length > 0) {
      variables.where = conditions.length === 1 ? conditions[0] : { AND: conditions };
    }
    return variables;
  }

  async createOne(data: T) {
    // this.clientIdValid(data);
    const obj = {
      param1: `($data: ${this.namespace}CreateInput!)`,
      param2: `(data: $data)`,
      namespace: this.namespace,
      namespaceLC: this.outputNamespace,
      command: `createOne${this.namespace}`,
      select: this.default_fields,
      openBrace: this.openBrace,
      closeBrace: this.closeBrace,
    };
    const mutation = Mustache.render(this.MUTATION_SKELETON, obj);
    const variables = this.iterateData(data as KeyValueObject, 'create');
    try {
      const res = await mutate<NamespaceKey<typeof this.typedNamespace, T>>({
        mutation: gql(mutation),
        variables,
      });
      return { ...res, data: res.data?.[this.namespace] };
    } catch (error) {
      throw new Error(`Mutation failed: ${error}`);
    }
  }

  async updateOne(data: T, softDelete = false, cascadeTables?: Array<string>) {
    // if (!softDelete) this.clientIdValid(data);
    const input = Object(data);
    if (!input.hasOwnProperty('id')) throw new Error('ID is missing');
    const obj = {
      param1: `($data: ${this.namespace}UpdateInput!)`,
      param2: `(where: { id: "${input.id}" }, data: $data)`,
      namespace: this.namespace,
      namespaceLC: this.outputNamespace,
      command: `updateOne${this.namespace}`,
      select: this.default_fields,
      openBrace: this.openBrace,
      closeBrace: this.closeBrace,
    };
    const mutation = Mustache.render(this.MUTATION_SKELETON, obj);
    const variables = this.iterateData(
      data as KeyValueObject,
      softDelete ? 'delete' : 'update',
      0,
      cascadeTables,
    );
    try {
      const res = await mutate<NamespaceKey<typeof this.typedNamespace, T>>({
        mutation: gql(mutation),
        variables,
      });
      return { ...res, data: res.data?.[this.namespace] };
    } catch (error) {
      throw new Error(`Mutation failed: ${error}`);
    }
  }

  async deleteOne(id: string, cascadeTables?: Array<string>) {
    const obj = { id } as unknown as T;
    return this.updateOne(obj, true, cascadeTables);
  }

  async selectOne(id: string) {
    const obj = {
      param1: `($where: ${this.namespace}WhereUniqueInput!)`,
      param2: `(where: $where)`,
      namespace: this.namespace,
      namespaceLC: this.outputNamespace,
      command: `${this.typedNamespace}`,
      select: this.default_fields,
      openBrace: this.openBrace,
      closeBrace: this.closeBrace,
    };
    const qry = Mustache.render(this.QUERY_SKELETON, obj);
    const variables = { where: { id } };
    try {
      const res = await query<NamespaceKey<typeof this.typedNamespace, T>>({
        variables,
        query: gql(qry),
      });
      return { ...res, data: res.data[this.namespace] };
    } catch (error) {
      throw new Error(`Query failed: ${error}`);
    }
  }

  async selectMany(
    orderBy: Array<SortItem>,
    params?: { [key: string]: string | boolean | null },
    fields?: string,
  ) {
    const plural =
      this.typedNamespace.lastIndexOf('y') === this.typedNamespace.length - 1
        ? this.typedNamespace.slice(0, -1) + 'ies'
        : this.typedNamespace + 's';

    const param1 = [];
    const param2 = [];

    if (params) {
      param1.push(`$where: ${this.namespace}WhereInput`);
      param2.push(`where: $where`);
    }
    if (orderBy) {
      param1.push(`$orderBy: [${this.namespace}OrderByWithRelationInput!]`);
      param2.push(`orderBy: $orderBy`);
    }

    const obj = {
      param1: param1.length > 0 ? `(${param1.join(', ')})` : '',
      param2: param2.length > 0 ? `(${param2.join(', ')})` : '',
      namespace: this.namespace,
      namespaceLC: this.outputNamespace,
      command: `${plural}`,
      select: fields ? fields : this.default_fields,
      openBrace: this.openBrace,
      closeBrace: this.closeBrace,
    };
    const qry = Mustache.render(this.QUERY_SKELETON, obj);
    const variables = this.setVariables(orderBy, params);

    try {
      const res = await query<NamespaceKey<typeof this.typedNamespace, Array<T>>>({
        variables,
        query: gql(qry),
      });
      return { ...res, data: res.data[this.namespace] };
    } catch (error) {
      throw new Error(`Query failed: ${error}`);
    }
  }

  async findFirst(
    cognito_user_id?: string,
    client_id?: string,
    survey_schema_id?: string,
    fields?: string,
  ) {
    const obj = {
      param1: `($where: ${this.namespace}WhereInput)`,
      param2: `(where: $where)`,
      namespace: this.namespace,
      namespaceLC: this.outputNamespace,
      command: `findFirst${this.namespace}`,
      select: fields ? fields : this.default_fields,
      openBrace: this.openBrace,
      closeBrace: this.closeBrace,
    };
    const qry = Mustache.render(this.QUERY_SKELETON, obj);
    const variables = client_id
      ? { where: { client_id: { equals: client_id } } }
      : cognito_user_id
        ? { where: { cognito_user_id: { equals: cognito_user_id } } }
        : survey_schema_id
          ? { where: { survey_schema_id: { equals: survey_schema_id } } }
          : {};

    try {
      const res = await query<NamespaceKey<typeof this.typedNamespace, T>>({
        variables,
        query: gql(qry),
      });
      return { ...res, data: res.data[this.namespace] };
    } catch (error) {
      throw new Error(`Query failed: ${error}`);
    }
  }
}
