import { gql } from '@apollo/client';
import { mutate, query } from '.';
import { VBBaseClass } from './generic';
import {
  Parameter,
  ParameterAgentListOutput,
  ParameterCreateInput,
  ParameterListOutput,
} from '@/generated/graphql';

const DEFAULT_FIELDS = `
  created
  id
  key_name
  value
  scenario_name
  type
`;

export class VBParameter {
  private static baseClass = new VBBaseClass<Parameter>('Parameter', DEFAULT_FIELDS, ['value']);

  static createOne(data: Parameter, crypt: boolean = false) {
    if (crypt) {
      const query = gql`
        mutation InsertParameterCrypt($data: ParameterCryptInsertInput!) {
          parameter: insertParameterCrypt(data: $data) {
            client_id
            id
            key_name
            scenario_name
            value
          }
        }
      `;
      console.log(data);
      return mutate<Parameter>({
        mutation: query,
        variables: {
          data,
        },
      });
    }

    const input: ParameterCreateInput = {
      key_name: data.key_name,
      scenario_name: data.scenario_name,
      value: data.value,
      client: { connect: { client_id: data.client_id } },
    };

    const query = gql`
      mutation CreateOneParameter($data: ParameterCreateInput!) {
        createOneParameter(data: $data) {
          client_id
          id
          key_name
          scenario_name
          value
        }
      }
    `;

    return mutate<Parameter>({
      mutation: query,
      variables: { data: input },
    });
  }

  static updateOne(data: Parameter, crypt: boolean = false) {
    if (crypt) {
      const query = gql`
        mutation UpdateParameterCrypt($data: ParameterCryptUpdateInput!) {
          parameter: updateParameterCrypt(data: $data) {
            client_id
            id
            key_name
            scenario_name
            value
          }
        }
      `;
      return mutate<Parameter>({
        mutation: query,
        variables: {
          data: {
            value: data.value,
            id: data.id,
          },
        },
      });
    }

    const query = gql`
      mutation UpdateOneParameter(
        $data: ParameterUpdateInput!
        $where: ParameterWhereUniqueInput!
      ) {
        updateOneParameter(data: $data, where: $where) {
          client_id
          id
          key_name
          scenario_name
          value
        }
      }
    `;

    return mutate<Parameter>({
      mutation: query,
      variables: {
        data: {
          value: data.value,
        },
        where: {
          id: data.id,
        },
      },
    });
  }

  static deleteOne(id: string) {
    return this.baseClass.deleteOne(id);
  }

  static selectOne(id: string) {
    const qry = gql`
      query GetParameter($where: ParameterInput!) {
        parameter: getParameterById(where: $where) {
          id
          key_name
          scenario_name
          value
          client_id
        }
      }
    `;

    return query<{ parameter: Parameter }>({
      query: qry,
      variables: { where: { id } },
    });
  }

  static selectManyByAgent() {
    const qry = gql`
      query parameterAgentList {
        parameter: parameterAgentList {
          id
          key_name
          scenario_name
          value
          agent_id
          client_id
        }
      }
    `;
    return query<{
      parameter: Array<ParameterAgentListOutput>;
    }>({
      query: qry,
    });
  }

  static selectManyByClient(client_id: string) {
    const qry = gql`
      query ParameterList($clientId: String!) {
        parameter: parameterList(client_id: $clientId) {
          id
          isGlobal
          key_name
          scenario_name
          type
          value
          agent_id
          client_id
        }
      }
    `;
    return query<{
      parameter: Array<ParameterListOutput>;
    }>({
      query: qry,
      variables: { clientId: client_id },
    });
  }
}
